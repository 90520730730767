<template>
    <!-- id : contents : (S) -->
    <div id='contents' class="st-login" style="background-color: white" >
        <!-- class : content-wrap : (S) -->
        <section class="content-wrap st-center st-login-guide" >
        <div class="main-wrap">
          <div class="inner-base-box mb-pd-none">
            <div class="subpage-title-box st-login-guide">
              <div class="tit-tab-box">
                <a href="javascript:;" :class="{on:tabIndex==6}" @click="tabIndex=6">e-CLUB 소개</a>
                <a href="javascript:;" :class="{on:tabIndex==7}" @click="tabIndex=7">회원가입 소개</a>
              </div>
            </div> 

            <MeetingGuide v-if="tabIndex==6"></MeetingGuide>
            <MeetingGuideMember v-else-if="tabIndex==7"></MeetingGuideMember>
          </div>
        </div>
        </section>

        <!-- class : content-wrap : (E) -->
    </div>
    <!-- id : contents : (E) -->
</template>

<script>
import MeetingGuideMember from '@/views/board/meeting/guide_eclub_member'
import MeetingGuide from '@/views/board/meeting/guide_eclub'
export default {
  name: 'loginGuide',
  components: {
    MeetingGuideMember,
    MeetingGuide
  },
  data() {
    return {
      tabIndex:6
    }
  },
}
</script>